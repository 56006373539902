import React from "react";
import "./css/index.css";
function Footer() {
  return (
    <footer>
      <div className="wrapper">
        <div className="top">
          <img src="/assets/footer/logo.svg" alt="" className="logo" />
          <div className="nav-wrapper">
            {navarr.map(({ title, link }, idx) => {
              return (
                <a href={link} key={idx} target={"_blank"} rel="noreferrer">
                  {title}
                </a>
              );
            })}
          </div>
        </div>
        <div className="content">
          (주)프로젝트퀘스천 | 대표 최은원 <span>|</span> <br className="mb" />{" "}
          사업자등록번호 797-81-01908 <br /> 통신판매업등록번호
          2024-서울중구-0103 호
        </div>
        <div className="info-wrapper">
          {infoarr.map(({ img, title }, idx) => {
            return (
              <div key={idx} className="info-line">
                <img src={img} alt="" />
                <div className="content">{title}</div>
              </div>
            );
          })}
        </div>
        <div className="bottom">
          <div className="sign">
            프로젝트퀘스천은 플랫폼 제공자로서 프로젝트의 당사자가 아니며,
            직접적인 통신판매를 진행하지 않습니다.
            <br /> 프로젝트의 완수 및 리워드 제공의 책임은 해당 프로젝트의
            팀플리더에게 있으며, 프로젝트와 관련하여 팀플러와 발생하는
            <br /> 법적분쟁에 대한 책임은 해당 팀플리더가 부담합니다.
          </div>
          <div className="sns">
            {sns_layouts.map(({ link, name, alt }, idx) => {
              return (
                <a
                  href={link}
                  target="_blank"
                  className={name}
                  key={idx}
                  rel="noreferrer"
                >
                  <img src={`/assets/footer/${name}.svg`} alt={alt} />
                </a>
              );
            })}
          </div>
        </div>
        <div className="copy">
          {" "}
          Copyright (주)프로젝트퀘스천, All Rights Reserved
        </div>
      </div>
    </footer>
  );
}

export default Footer;

const sns_layouts = [
  {
    link: "https://www.facebook.com/myteample/",
    name: "facebook",
    alt: "페이스북",
  },
  {
    link: "https://www.instagram.com/myteample/",
    name: "instagram",
    alt: "인스타그램",
  },
  {
    link: "https://www.youtube.com/channel/UCyghIfHg6OdZloiOsyrazQw",
    name: "youtube",
    alt: "유튜브",
  },
  {
    link: "https://blog.naver.com/project_question",
    name: "blog",
    alt: "네이버 블로그",
  },
];

const navarr = [
  {
    title: "팀플리더 신청",
    link: "https://pqcompany.imweb.me/",
  },
  {
    title: "서비스소개",
    link: "https://projectquestion.notion.site/My-teample-0871ac52203048f78d92cc68625e175d",
  },
  {
    title: "약관 및 정책",
    link: "/policy",
  },
  {
    title: "Q&A",
    link: "/inquiry",
  },
];
const infoarr = [
  {
    img: "/assets/footer/pin.svg",
    title: "서울특별시 강서구 마곡중앙로 105-7, K 스퀘어 마곡 타워1 3 층 332호",
  },

  {
    img: "/assets/footer/mail.svg",
    title: "myteample@myteample.com",
  },
];
